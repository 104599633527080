import React, { useState } from "react"
import * as styles from "./hamburger.module.css"

type Props = {
  onClick: () => void,
  open: Boolean
}

const Hamburger: React.FC<Props> = ({ onClick, open }) => {
  const toggleClick = () => {
    onClick()
  }

  return (
    <div
      onClick={toggleClick}
      className={[styles.hamburger, styles.spin, open ? styles.open : styles.closed].join(" ")}
    >
      <div className={styles.lines}></div>
    </div>
  )
}

export default Hamburger
