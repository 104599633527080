import React, { FC } from "react"
import * as styles from "./footer.module.css"
import InternalLink from "../link/internalLink"
import { Settings } from "../../types/settings.types"
import Image from "../image/image"
import IconTextLink from "../iconTextLink/iconTextLink"
import ContentActionButton, { Layout } from "../actionButton/contentActionButton"

const Footer: FC<Settings & { className: string }> = ({
  footerLinks,
  socialLinks,
  className = "",
  whiteLogo,
  contactDetails,
}) => {
  return (
    <footer className={[styles.footer, className].join(" ")}>
      <div className={styles.footerInner}>
        <div className={styles.top}>
          <div className={styles.logo}>
            {whiteLogo && <Image {...whiteLogo} fit="contain" height={80} />}
          </div>
          <div className={styles.contact}>
            {contactDetails &&
              contactDetails.details &&
              contactDetails.details.map(c => <IconTextLink {...c} key={c.text} />)}
          </div>
        </div>
        {footerLinks && footerLinks.length > 0 && <div className={styles.middle}>
          <nav className={styles.nav}>
            {footerLinks && footerLinks.map(l => <InternalLink reference={l} key={l.id} />)}
          </nav>
          <div>
            <ContentActionButton
              document={{ href: "/contact", blank: false }}
              text="Book Consultation"
              layout={Layout.LargeButton}
              className={styles.actionButton}
            />
          </div>
        </div>}
        <div className={styles.bottom}>
          <div className={styles.copyright}>Copyright. All rights reserved.</div>
          <div className={styles.social}>
            {socialLinks &&
              socialLinks.details &&
              socialLinks.details.map(s => (
                <IconTextLink
                  icon={s.icon}
                  link={s.link}
                  key={s.text}
                  className={styles.socialIcon}
                />
              ))}
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
