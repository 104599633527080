/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { ReactNode, useContext } from "react"
import PropTypes from "prop-types"

import Header from "../components/header/header"
import * as styles from "./layout.module.css"

import { PageContext, PageContextData } from "../contexts/pageContext"
import { FontIcon } from "../components/icons/icons"
import Footer from "../components/footer/footer"

const Layout: React.FC<{ children: ReactNode }> = props => {
  const { children } = props
  const context = useContext<PageContextData>(PageContext)

  return (
    <>
      <div className={styles.topColorStrip} />
      <div className={styles.wrapper}>
        <Header
          logo={context.settings.colorLogo}
          links={context.settings.headerLinks}
          className={styles.header}
        />
        <main className={styles.main}>{children}</main>
        <Footer {...context.settings} className={styles.footer} />
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node,
}

export default Layout
