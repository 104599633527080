import React, { FunctionComponent } from "react"
import Layout from "../containers/layout"
import SEO from "../components/seo/seo"
import { PageContext, PageContextData } from "../contexts/pageContext"
import BlockContent from "../components/blockContent/blockContent"

const IndexPage: FunctionComponent<{ router?: string; pageContext: PageContextData }> = ({
  router,
  pageContext,
}) => {
  const { page } = pageContext
  console.log(page)
  if (typeof document !== "undefined") {
    const isIE = !!document.documentMode

    if (isIE) {
      window.location.href = "/oldbrowser/"
    }
  }
  return (
    <PageContext.Provider value={pageContext}>
      <Layout>
        <SEO title={page.title} description={page.description} />
        <BlockContent blocks={page._rawContent} context={page} />
      </Layout>
    </PageContext.Provider>
  )
}

export default IndexPage
