import { Link } from "gatsby"
import React, { useEffect, useRef, useState } from "react"
import Hamburger from "../hamburger/hamburger"
import styles from "./header.module.css"
import { Content, ContentImage } from "../../types/content.types"
import InternalLink from "../link/internalLink"
import Image from "../image/image"
import ContentActionButton from "../actionButton/contentActionButton"
import { Layout } from "../actionButton/contentActionButton"
import { useWindowIsResizing } from "../../utils/hooks/window.hooks"

type Props = {
  logo: ContentImage
  links: Array<Content>
  className?: string
}

const Header: React.FC<Props> = ({ logo, links, className }) => {
  const [menuVisible, setMenuVisible] = useState(false)
  const resizing = useWindowIsResizing()

  return (
    <header
      className={[
        styles.header,
        menuVisible ? styles.open : styles.closed,
        className || "",
        resizing ? "" : styles.animate,
      ].join(" ")}
    >
      <div className={[styles.hamburger, styles.item].join(" ")}>
        <Hamburger onClick={() => setMenuVisible(!menuVisible)} open={menuVisible} />
      </div>
      <div className={[styles.logo, styles.item].join(" ")}>
        <Link to="/" className={styles.logoLink}>
          <Image {...logo} alt="Site logo" fit="contain" lazyLoad={false} />
        </Link>
      </div>
      <nav className={styles.nav}>
        <div className={styles.navInner}>
          {links &&
            links.map(l => <InternalLink reference={l} className={styles.item} key={l.id} />)}
        </div>
      </nav>
    </header>
  )
}

export default Header
