import { useEffect, useRef, useState } from "react"

export const useWindowIsResizing = () => {
  const isClient = typeof window === "object"
  const [resizing, setResizing] = useState(false)
  const timeoutRef = useRef(0)

  const handleResize = () => {
    setResizing(true)

    if (timeoutRef.current !== 0) {
      clearTimeout(timeoutRef.current)
    }

    timeoutRef.current = setTimeout(() => setResizing(false), 1000)
  }

  useEffect(() => {
    if (!isClient) {
      return
    }

    window.addEventListener("resize", handleResize)

    return () => window.removeEventListener("resize", handleResize)
  }, [])

  return resizing
}
